
import Vue from "vue";
import { filter, isNull, isEmpty } from 'lodash'
// @ts-ignore
import { TypeLoading } from "@/interfaces/loading";
//@ts-ignore
import { getPermisionSegmento } from "@/utils/permissionResolve";
import { mapGetters } from "vuex";

export default Vue.extend({

    name:"index",
    props:{
        segment: {
            type: Object,
            default: function () {
                return {};
            }
        },
        segmentCategoriesList: {
			type: Array,
			required: true,
		},
    },

    data: () => ({
        items:[],
        selection:[],
    }),

    created() {
    },

    async mounted() {
        this.getSegmentCategoriesByParentID(this.getSegmentCategories);
    },

    computed: {
        ...mapGetters("profile", ["getAbility"]),

        getSegment() {
            return this.segment
        },

        getItemSelections() {
            return this.selection
        },

        getSegmentCategories() {
            return this.segmentCategoriesList;
        },

        hasSegmentCategories() {
            return isEmpty(this.getSegmentCategories);
        },

        canSegment(){
            return (this.canIndex && this.canEdit);
        },

        canIndex(){
			return this.getAbility.can(this.getPermission.actions.index, this.getPermission.subject);
		},

        canEdit(){
			return this.getAbility.can(this.getPermission.actions.update, this.getPermission.subject);
		},

		getPermission(){
			return getPermisionSegmento();
		}
    },

    methods:{

        goToSegmentCategories(){
            this.$router.push({ name: "SegmentCategoryCreate", query: { segment_id: this.segment.id, advertiser_id: this.segment.advertiser_id } });
        },

        getSegmentCategoriesByParentID(values: any){
            this.items = [];
            if(isEmpty(values)) return
            let parents = this.getSegmentCategoriesParent(null);
            parents.forEach(element => {
                let hijos = this.getFuncionRecursiva(element.id);
                let item = {
                    id: element.id,
                    name: element.name,
                    children: hijos
                }
                if(this.segment.id){
                    if(this.isElement(item.id)){
                        this.selection.push(item);
                    }
                }
                this.items.push(item)
            });
        },

        getFuncionRecursiva(PID: number){
            let segmentos = this.getSegmentCategoriesParent(PID);
            let arr = new Array();
            if(isEmpty(segmentos)) return 
            segmentos.forEach(element => {
                let hijos = this.getFuncionRecursiva(element.id);
                let item = {
                    id: element.id,
                    name: element.name,
                    children: hijos
                }
                if(this.segment.id){
                    if(this.isElement(item.id)){
                        this.selection.push(item);
                    }
                }
                arr.push(item)
                if(!isNull(element.parent_category)){
                    this.getFuncionRecursiva(element.id);
                }
            })
            return arr;
        },

        getSegmentCategoriesParent(params: any){
            return filter(this.getSegmentCategories, { "parent_id": params });
        },

        getElementSelected(){
            let values = new Array();
            this.getItemSelections.forEach(element => {
                values.push(element.id);
            });
            return values;
        },

        isElement(id: number){
            let values = false;
            this.segment.categories.forEach(element => {
                if(element.id == id){
                    values = true;
                }
            });
            return values;
        },

        handleCancel(){
            this.$emit("cancel");
        },

        handleSubmit() {
            this.segment.categories = this.getElementSelected();
            this.$emit("submit-categories", {segment: this.segment, redirect: false});
        },


        async fetchSegmentCategories(e: any) {
            if (!isEmpty(this.getSegmentCategories)) return;
            this.$emit("fetch-segment-category");
        }
    },

    watch:{
		segmentCategoriesList(old, val){
            this.items = [];
            this.getSegmentCategoriesByParentID(this.getSegmentCategories);
		}
	}
});
